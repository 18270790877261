
import { getTableDetail, setCommissionTable } from '@/api'
import { RoadMapData } from '@/components/roadmap/datamodel/RoadMapData'
import { parseRoadMap } from '@/components/roadmap/utils'
import { ROUTES } from '@/router'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss, SOCKET_MESSAGE } from '@/types/global'
import { generateRandomNumber, getDeviceOrientation } from '@/utils'
import {
    Emitter,
    LimitOption,
    PropType,
    ResponseData,
    ResultMemberList,
    SocketMessage,
    TableData,
    TableDetails,
    computed,
    defineComponent,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
} from 'vue'
import { useRouter } from 'vue-router'
import { VTextMarquee } from 'vue-text-marquee'
import {
    IconNotice,
    SvgDolar,
    SvgFullScreenToggle,
    SvgSetting,
    IconStatistics,
    IconAllRoad,
    IconBigRoad,
    IconThumbsUp4
} from '@/components/icons'
import RoadMap from '@/components/roadmap/index.vue'
import DPrediction from '@/components/d-prediction.vue'
import CountDownTimer from '@/components/timer-count-down.vue'
import GameChipControl from '@/components/custom-controls/game-chip-control-v2.vue'
import { RoadMapSummaryData } from '@/components/roadmap/datamodel/types'
import { useCountdown } from '@/composables/useCountDown'
import { useI18n } from 'vue-i18n'
import CardResultV1 from '@/components/custom-controls/card-result-v1.vue'
import PcDefaultBetGroupV1 from '@/components/bet/pc-default-bet-group-v1.vue'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import MobileChipControlH from '@/components/mobile-chip-control-horizontal.vue'
import MobilePrediction from '@/components/custom-controls/mobile-prediction.vue'
import MobileBetGroup from '@/components/bet/mobile-default-bet-group-v1.vue'
import ABDCardResult from '@/components/d-multi-table-card-result.vue'
import VideoLoader from '@/components/video-loader.vue'
export default defineComponent({
    emits: ['show-dialog', 'show-switch-desk'],
    props: {
        desk: {
            default: -1,
            type: Number as PropType<number>
        },
        hall: {
            type: Number as PropType<number>,
            default: 1
        }
    },
    components: {
        'road-map': RoadMap,
        'marquee-text': VTextMarquee,
        'd-prediction': DPrediction,
        'timer': CountDownTimer,
        'game-chip-control': GameChipControl,
        'card-result-v1': CardResultV1,
        'icon-notice': IconNotice,
        'svg-dolar': SvgDolar,
        'svg-full-screen-toggle': SvgFullScreenToggle,
        'svg-setting': SvgSetting,
        'icon-statistics': IconStatistics,
        'icon-all-road': IconAllRoad,
        'icon-big-road': IconBigRoad,
        'icon-thumbs-4': IconThumbsUp4,
        'pc-bet-group': PcDefaultBetGroupV1,
        'm-chip-control-h': MobileChipControlH,
        'm-prediction': MobilePrediction,
        'mobile-bet-group': MobileBetGroup,
        'm-d-card-result': ABDCardResult,
        'video-loader': VideoLoader
    },
    setup(props) {
        const emitter = inject('emitter') as Emitter
        const router = useRouter()
        const store = useStore()
        const { t } = useI18n()

        const deskVideoOn = ref<boolean>(false)
        const deskNoticeKey = ref<number>(new Date().getTime())
        const deskNotice = ref<string>('')
        const deskData = ref<TableDetails | undefined>(undefined)
        const roadMapData = ref<RoadMapData>(parseRoadMap([]))
        const deskMapKey2 = ref<string>(`map2${new Date().getTime()}`)
        const deskMapKey1 = ref<string>(`map1${new Date().getTime()}`)
        const deskMapKey3 = ref<string>(`map3${new Date().getTime()}`)
        const deskMapKey4 = ref<string>(`map4${new Date().getTime()}`)
        const cardResultKey = ref<number>(Date.now())

        const hideVideoCover = ref(false)

        const showCardDisplay = ref(false)

        const nextRedRoadMapSummaryData = ref<RoadMapSummaryData>({})
        const nextBlueRoadMapSummaryData = ref<RoadMapSummaryData>({})

        const enableOpenPoker = ref<boolean>(false)

        const displayOption = ref(0)

        const refBetGroup = ref<any>()
        const refMobileBetGroup = ref<any>()

        const mapLeft = ref()
        const mapRight = ref()

        const mapMLeft = ref()
        const mapMRight = ref()

        const gameKeyRef = ref<string>(`game-ref-key${new Date().getTime()}`)

        let isFirstLoad = true

        const chip = ref()
        const videoBg = ref(generateRandomNumber(1, 8))
        const winResult = ref<number[]>([])
        const hasTempBet = ref(false)
        const betViewKey = ref<string>(`betview${new Date().getTime()}`)

        let videoPlayerCollection: Array<any> = new Array<any>()
        let nodePlayer!: any
        const isLoadingVideo = ref<boolean>(true)
        let videoTimeOut: number | boolean | any = false

        const toastMessage = ref<
            { id: number | boolean | any; message: string }[]
        >([])

        let loadDataFailedCounter = 0

        let showCardTimeout: number | boolean | any = false

        const { currentCount, startCountDown, stopCountDown } = useCountdown(
            true,
            () => onCountDownStop()
        )

        // let deskId = -1

        //#region Vue Helpers

        onMounted(() => {
            deskVideoOn.value = videoIsOn.value
            removeallToast()
        })

        onBeforeMount(() => {
            emitter.on(EventBuss.PAGE_ON_VISIBLE, handleOnPageActive)
            emitter.on(EventBuss.SMALL_TOAST, handleSmallToastMessage)
            emitter.on(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
            emitter.on(EventBuss.WINDOW_RESIZED, handleOnWindowResize)
        })

        onBeforeUnmount(() => {
            stopCountDown()
            disposeVideoPlayer()
            removeallToast()

            emitter.off(EventBuss.PAGE_ON_VISIBLE, handleOnPageActive)
            emitter.off(EventBuss.SMALL_TOAST, handleSmallToastMessage)
            emitter.off(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
            emitter.off(EventBuss.WINDOW_RESIZED, handleOnWindowResize)
        })

        //#endregion

        //#region Websocket Helpers

        const handleSocketMessage = (_message: SocketMessage | any) => {
            const { type, gameTable } = _message
            const _type = type.toUpperCase()

            if (_type === SOCKET_MESSAGE.NEW_GAME) {
                const sDesk = gameTable!

                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }
                    _gtableCopy.expand.shoeNo = sDesk.expand.shoeNo
                    _gtableCopy.expand.juNo = sDesk.expand.juNo
                    _gtableCopy.expand.gameNo = sDesk.expand.gameNo
                    _gtableCopy.expand.daoJiShiBetTime =
                        sDesk.expand.daoJiShiBetTime

                    // reset all data
                    _gtableCopy.expand.betFormList = []
                    _gtableCopy.expand.xianAmount = 0
                    _gtableCopy.expand.xianDuiAmount = 0
                    _gtableCopy.expand.zhuangAmount = 0
                    _gtableCopy.expand.zhuangDuiAmount = 0
                    _gtableCopy.expand.heAmount = 0
                    // reset card result
                    _gtableCopy.expand.playerOne = undefined
                    _gtableCopy.expand.playerTwo = undefined
                    _gtableCopy.expand.playerThree = undefined
                    _gtableCopy.expand.bankerOne = undefined
                    _gtableCopy.expand.bankerTwo = undefined
                    _gtableCopy.expand.bankerThree = undefined
                    _gtableCopy.expand.playerCount = undefined
                    _gtableCopy.expand.bankerCount = undefined
                    _gtableCopy.expand.whoWin = -1

                    _gtableCopy.downLimit = sDesk.downLimit

                    _gtableCopy.status = 1

                    deskData.value!.gameTable = _gtableCopy
                    emitter.emit(EventBuss.GAMESTATUS, 1)
                }
            } else if (_type === SOCKET_MESSAGE.END_GAME) {
                const sDesk = gameTable!
                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }

                    _gtableCopy.expand.playerOne = sDesk.expand.playerOne
                    _gtableCopy.expand.playerTwo = sDesk.expand.playerTwo
                    _gtableCopy.expand.playerThree = sDesk.expand.playerThree
                    _gtableCopy.expand.bankerOne = sDesk.expand.bankerOne
                    _gtableCopy.expand.bankerTwo = sDesk.expand.bankerTwo
                    _gtableCopy.expand.bankerThree = sDesk.expand.bankerThree
                    _gtableCopy.expand.playerCount = sDesk.expand.playerCount
                    _gtableCopy.expand.bankerCount = sDesk.expand.bankerCount
                    _gtableCopy.expand.whoWin = sDesk.expand.whoWin
                    _gtableCopy.expand.resultList = sDesk.expand.resultList
                    _gtableCopy.expand.gameRecordList =
                        sDesk.expand.gameRecordList

                    // reset bet data
                    _gtableCopy.expand.betFormList = []
                    _gtableCopy.expand.xianAmount = 0
                    _gtableCopy.expand.xianDuiAmount = 0
                    _gtableCopy.expand.zhuangAmount = 0
                    _gtableCopy.expand.zhuangDuiAmount = 0
                    _gtableCopy.expand.heAmount = 0
                    _gtableCopy.expand.daoJiShiBetTime = -1
                    _gtableCopy.downLimit = sDesk.downLimit

                    const memberResultList = sDesk.expand
                        .resultMemberList as ResultMemberList[]
                    if (memberResultList && memberResultList.length > 0) {
                        const _memberResultInfo = memberResultList.find(
                            (x) => x.memberId === memberid.value
                        )

                        if (_memberResultInfo) {
                            store.commit(
                                AppMutation.SET_COIN,
                                _memberResultInfo.amount
                            )

                            if (_memberResultInfo.winResult !== 0) {
                                let _amtStr = '0'

                                if (_memberResultInfo.winResult > 0)
                                    _amtStr = `+${_memberResultInfo.winResult}`
                                else
                                    _amtStr = String(
                                        _memberResultInfo.winResult
                                    )

                                emitter.emit(EventBuss.SMALL_TOAST, {
                                    msg: _amtStr,
                                    id: deskData.value?.gameTable.id || 0
                                })
                            }
                        }
                    }

                    deskData.value!.gameTable = _gtableCopy

                    if (typeof showCardTimeout === 'number') {
                        clearTimeout(showCardTimeout)
                        showCardTimeout = false
                        showCardDisplay.value = false
                    }
                    cardResultKey.value = Date.now()
                    showCardDisplay.value = true

                    showCardTimeout = setTimeout(() => {
                        showCardDisplay.value = false
                        winResult.value = []
                    }, 6000)

                    if (refBetGroup.value || refMobileBetGroup.value) {
                        setTimeout(() => {
                            if (refBetGroup.value) refBetGroup.value.endGame()
                            if (refMobileBetGroup.value)
                                refMobileBetGroup.value.endGame()
                        }, 250)
                    }
                }
            } else if (_type === SOCKET_MESSAGE.CANCEL_GAME) {
                const sDesk = gameTable!
                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }
                    _gtableCopy.status = sDesk.status

                    _gtableCopy.downLimit = sDesk.downLimit

                    _gtableCopy.expand.betFormList = []
                    _gtableCopy.expand.xianAmount = 0
                    _gtableCopy.expand.xianDuiAmount = 0
                    _gtableCopy.expand.zhuangAmount = 0
                    _gtableCopy.expand.zhuangDuiAmount = 0
                    _gtableCopy.expand.heAmount = 0
                    _gtableCopy.expand.daoJiShiBetTime = -1

                    const memberResultList = sDesk.expand
                        .resultMemberList as ResultMemberList[]

                    if (memberResultList && memberResultList.length > 0) {
                        const _memberResultInfo = memberResultList.find(
                            (x) => x.memberId === memberid.value
                        )

                        if (_memberResultInfo) {
                            store.commit(
                                AppMutation.SET_COIN,
                                _memberResultInfo.amount
                            )

                            if (refBetGroup.value || refMobileBetGroup.value) {
                                setTimeout(() => {
                                    if (refBetGroup.value)
                                        refBetGroup.value.endGame()
                                    if (refMobileBetGroup.value)
                                        refMobileBetGroup.value.endGame()
                                }, 250)
                            }
                        }
                    }

                    deskData.value!.gameTable = _gtableCopy
                }
            } else if (_type === SOCKET_MESSAGE.STOP_GAME) {
                const sDesk = gameTable!
                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }

                    _gtableCopy.downLimit = sDesk.downLimit
                    // _gtableCopy.upLimit = sDesk.upLimit
                    _gtableCopy.expand.daoJiShiBetTime = 0
                    _gtableCopy.status = 2
                    deskData.value!.gameTable = _gtableCopy
                    audioPlayer.Play(SOUNDKEY.STOP_BET)
                    emitter.emit(EventBuss.GAMESTATUS, 2)
                }
            } else if (_type === SOCKET_MESSAGE.BET) {
                const sDesk = gameTable!
                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }
                    _gtableCopy.expand.xianAmount = sDesk.expand.xianAmount
                    _gtableCopy.expand.xianDuiAmount =
                        sDesk.expand.xianDuiAmount
                    _gtableCopy.expand.zhuangAmount = sDesk.expand.zhuangAmount
                    _gtableCopy.expand.zhuangDuiAmount =
                        sDesk.expand.zhuangDuiAmount
                    _gtableCopy.expand.heAmount = sDesk.expand.heAmount
                    _gtableCopy.downLimit = sDesk.downLimit

                    if (sDesk.expand.memberId === memberid.value) {
                        _gtableCopy.expand.betFormList =
                            sDesk.expand.betFormList
                        store.commit(AppMutation.SET_COIN, sDesk.expand.amount)
                        // store.commit(AppMutation.SET_CLIENT_TIME_OUT,15)
                    }
                    deskData.value!.gameTable = _gtableCopy
                }
            } else if (_type === SOCKET_MESSAGE.NEW_SHOE) {
                const sDesk = gameTable!
                if (isSameTable(sDesk) && deskData.value) {
                    sDesk.upLimit = deskData.value.gameTable.upLimit
                    deskData.value.gameTable = sDesk
                }
            }
        }
        const isSameTable = (_table: TableData): boolean => {
            if (deskData.value && deskData.value.gameTable.id === _table.id)
                return true
            else return false
        }

        //#endregion

        //#region Helpers

        const handleOnPageActive = () => {
            loadData(true)
        }

        const handleOnWindowResize = () => {
            deskMapKey2.value = `map2${new Date().getTime()}`
            deskMapKey1.value = `map1${new Date().getTime()}`
            deskMapKey3.value = `map3${new Date().getTime()}`
            deskMapKey4.value = `map4${new Date().getTime()}`
        }
        const removeallToast = () => {
            toastMessage.value.forEach((toastM) => {
                if (typeof toastM.id === 'number') {
                    clearTimeout(toastM.id)
                    toastM.id = false
                }
            })

            toastMessage.value = []
        }

        const onRoadMapDataParsed = (
            a: RoadMapData,
            b: RoadMapData,
            c: RoadMapData
        ) => {
            roadMapData.value = a
            nextRedRoadMapSummaryData.value = { data: b }
            nextBlueRoadMapSummaryData.value = { data: c }
        }

        const handleSmallToastMessage = (message: {
            msg: string
            id: number
        }) => {
            if (deskData.value && deskData.value.gameTable.id === message.id) {
                const _t = {
                    id: setTimeout(() => {
                        const _tt = toastMessage.value.shift()
                        if (_tt && typeof _tt.id === 'number') {
                            clearTimeout(_tt.id)
                        }
                    }, 1500),
                    message: message.msg
                }
                toastMessage.value.push(_t)
            }
        }

        const showUiGameResult = (_results: number[]) => {
            winResult.value = _results
        }

        const handleBetgroupUpdate = (_hasTempBet: boolean) => {
            hasTempBet.value = _hasTempBet
        }

        const onCountDownStop = () => {
            cancelTempbet()
        }

        //#endregion

        //#region Click Helpers
        const requestFullScreen = () => {
            emitter.emit(EventBuss.REQUEST_FULL_SCREEN)
        }
        const predict = (i: number) => {
            if (mapLeft.value) {
                mapLeft.value.Predict(i)
            }

            if (mapRight.value) {
                mapRight.value.Predict(i)
            }

            if (mapMRight.value) {
                mapMRight.value.Predict(i)
            }

            if (mapMLeft.value) {
                mapMLeft.value.Predict(i)
            }
        }

        const cancelTempbet = (isMobile = false) => {
            if (hasTempBet.value) {
                if (refBetGroup.value) {
                    refBetGroup.value.resetTempBet()
                }

                if (refMobileBetGroup.value) {
                    refMobileBetGroup.value.resetTempBet()
                }
            }
        }

        const confirmTempBet = (isMobile = false) => {
            if (deskData.value && deskData.value.gameTable.status === 1) {
                if (
                    refBetGroup.value &&
                    deviceOrientation.value === 'landscape'
                )
                    refBetGroup.value.confirmTempBet()

                if (
                    refMobileBetGroup.value &&
                    deviceOrientation.value === 'portrait'
                )
                    refMobileBetGroup.value.confirmTempBet()
            }
        }

        const toggleDisplayOptionView = () => {
            if (displayOption.value === 2) {
                displayOption.value = 0
            } else {
                displayOption.value++
            }
            deskMapKey3.value = `map3${new Date().getTime()}`
            deskMapKey4.value = `map4${new Date().getTime()}`
        }
        //#endregion

        //#region API Call Helpers
        const loadData = (_forceReload = false) => {
            if (props.desk <= 0) {
                router.push({ name: ROUTES.LOBY })
                return
            }

            const _params = {
                memberId: memberid.value,
                tableId: props.desk,
                token: token.value
            }

            if (!_forceReload) store.commit(AppMutation.SHOW_LOADING)
            getTableDetail(_params)
                .then((_response) => {
                    loadDataFailedCounter = 0
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)
                    const responseData =
                        _response.data as ResponseData<TableDetails>

                    if (responseData.status === 200) {
                        if (
                            responseData.data?.gameTable.gameTypeNo === 'G001'
                        ) {
                            router.push({ name: ROUTES.LOBY })
                            return
                        }

                        const { noticeList, gameTable } = responseData.data!
                        let _deskNotice = ''
                        noticeList.forEach((_notice, index) => {
                            if (index === 0) _deskNotice = _notice.content
                            else
                                _deskNotice = `${_deskNotice},   ${_notice.content}`
                        })
                        deskNotice.value = _deskNotice
                        deskNoticeKey.value = new Date().getTime()

                        deskData.value = responseData.data!

                        store.commit(
                            AppMutation.SET_COIN,
                            deskData.value.gameTable.expand.amount
                        )
                        roadMapData.value = parseRoadMap(
                            deskData.value.gameTable.expand.resultList
                        )

                        if (refBetGroup.value || refMobileBetGroup.value) {
                            setTimeout(() => {
                                if (refBetGroup.value)
                                    refBetGroup.value.resetBetUI()
                                if (refMobileBetGroup.value)
                                    refMobileBetGroup.value.resetBetUI()

                                if (
                                    deskData.value &&
                                    deskData.value.gameTable.expand.betFormList
                                ) {
                                    if (deskData.value.gameTable.status === 1) {
                                        deskData.value.gameTable.expand.betFormList.forEach(
                                            (i) => {
                                                if (
                                                    i.memberId ===
                                                    memberid.value
                                                ) {
                                                    enableOpenPoker.value = true
                                                }
                                            }
                                        )
                                    }
                                }
                            }, 250)
                        }

                        if (deskVideoOn.value) initVideoPlayer()
                    } else if (responseData.status === 301) {
                        // token is expired
                        // reset
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )
                        emitter.emit(EventBuss.SOCKET_CLOSE)
                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )
                    }
                })
                .catch((e) => {
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)

                    // emitter.emit(EventBuss.TOAST_MESSAGE, '网络错误')

                    loadDataFailedCounter++
                    if (loadDataFailedCounter > 3) {
                        emitter.emit(
                            EventBuss.SMALL_TOAST,

                            {
                                msg: '当前网络不佳，请重新尝试',
                                id: deskData.value?.gameTable.id || 0
                            }
                        )

                        emitter.emit(EventBuss.SOCKET_CLOSE)
                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        // reload after 1 second
                        setTimeout(() => {
                            loadData(true)
                        }, 1500)
                    }
                })
        }

        const setMemberCommission = () => {
            if (!deskCommissionStatus.value) return
            if (deskData.value && deskData.value.gameTable.expand.betFormList) {
                let totalBet = 0
                deskData.value.gameTable.expand.betFormList.forEach((i) => {
                    if (i.memberId === memberid.value) {
                        totalBet += i.amount
                    }
                })

                if (totalBet > 0) return
            }

            const _params = {
                memberId: memberid.value,
                tableId: deskData.value?.gameTable.id || 0,
                status: memberCommisionStatus.value ? -1 : 1,
                token: token.value
            }

            store.commit(AppMutation.SHOW_LOADING)

            setCommissionTable(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (response.data.status === 100) {
                        emitter.emit(EventBuss.SMALL_TOAST, {
                            msg: response.data.description,
                            id: deskData.value?.gameTable.id || 0
                        })
                    } else if (response.data.status === 200) {
                        const _v = memberCommisionStatus.value ? -1 : 1
                        if (deskData.value) {
                            deskData.value.gameTable.expand.memberCommissionStatus =
                                _v
                        }
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(EventBuss.SMALL_TOAST, {
                        msg: '当前网络不佳，请重新尝试',
                        id: deskData.value?.gameTable.id || 0
                    })
                })
        }

        const backHistory = () => {
            // history.go(-1)
            router.push({ name: ROUTES.LOBY })
        }

        const toggleShowExtraLimitInfo = () => {
            store.commit(
                AppMutation.SET_DISPLAY_TABLE_INFO,
                !displayTableInfo.value
            )
        }

        //#endregion

        //#region Video Player Helpers
        const initVideoPlayer = () => {
            isLoadingVideo.value = true
            disposeVideoPlayer()

            const videoPaths = [process.env.VIDEO1, process.env.VIDEO2]

            let isVideoConnected = false

            const videoUrl = deskData.value?.gameTable.cmccLine || ''

            videoPaths.forEach((vPath) => {
                const _videoUrl =
                    vPath +
                    '/' +
                    videoUrl.replace(/(http:|)(^|\/\/)(.*?\/)/g, '')

                // eslint-disable-next-line no-undef
                let _player: NodePlayer | any = new NodePlayer()

                _player.setVolume(0.0)

                if (getDeviceOrientation() === 'landscape') {
                    _player.setView('videoplayer2')
                    // set scale mode to normal
                    _player.setScaleMode(0)
                } else {
                    _player.setView('m-videoplayer2')
                    _player.setScaleMode(2)
                }

                _player.setBufferTime(1000)

                _player.on('start', () => {
                    if (!isVideoConnected) {
                        isVideoConnected = true
                        nodePlayer = _player
                        nodePlayer.clearView()

                        if (getDeviceOrientation() === 'landscape') {
                            nodePlayer.setView('videoplayer')
                        } else {
                            nodePlayer.setView('m-videoplayer')
                        }
                        nodePlayer.on(
                            'buffer',
                            (evt: 'empty' | 'buffering' | 'full') => {
                                if (evt === 'empty' || evt === 'buffering') {
                                    isLoadingVideo.value = true
                                } else if (evt === 'full') {
                                    isLoadingVideo.value = false
                                    if (!hideVideoCover.value)
                                        hideVideoCover.value = true
                                }
                            }
                        )
                        nodePlayer.on('error', (e: any) => {
                            isLoadingVideo.value = true
                            setTimeout(() => {
                                nodePlayer.clearView()
                                nodePlayer.stop()
                                initVideoPlayer()
                            }, 1000)
                        })
                    } else {
                        _player.clearView()
                        _player.stop()
                    }
                })
                _player.on('error', () => {
                    //
                })
                videoPlayerCollection.push(_player)
                _player.start(_videoUrl)
            })
        }
        const disposeVideoPlayer = () => {
            if (nodePlayer) {
                nodePlayer.stop()
                nodePlayer.clearView()
                nodePlayer = undefined
                isLoadingVideo.value = true
            }

            videoPlayerCollection.forEach((_player) => {
                if (_player) {
                    _player.clearView()
                    _player.stop()
                }
            })

            videoPlayerCollection = []
        }
        //#endregion

        //#region Computed
        const videoIsOn = computed(() => store.getters['videoIsOn'])
        const token = computed(() => store.getters['token'])
        const memberid = computed(() => store.getters['memberid'])
        const coin = computed(() => store.getters['coin'])
        const isfullScreen = computed(() => store.getters['isfullScreen'])
        const userLimit = computed((): number => store.getters.userLimit)
        const limitOptionArray = computed(
            (): LimitOption[] => store.getters['limits']
        )
        const displayTableInfo = computed(
            (): boolean => store.getters['displayTableInfo']
        )

        const deviceOrientation = computed(
            (): 'portrait' | 'landscape' => store.getters['deviceOrientation']
        )

        const deskCommissionStatus = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.commissionStatus === 1
            }
            return false
        })

        const memberCommisionStatus = computed(() => {
            if (deskData.value) {
                return (
                    deskData.value.gameTable.expand.memberCommissionStatus === 1
                )
            }
            return true
        })

        const mapData = computed(() => {
            if (deskData.value)
                return deskData.value.gameTable.expand.resultList
            return []
        })

        const gameType = computed(() => {
            if (deskData.value)
                return deskData.value.gameTable.gameTypeNo.toUpperCase()

            return 'G002'
        })

        const redLabel = computed(() =>
            gameType.value === 'G002'
                ? t('roadmap.banker')
                : t('roadmap.dragon')
        )
        const blueLabel = computed(() =>
            gameType.value === 'G002' ? t('roadmap.player') : t('roadmap.tiger')
        )
        const redCount = computed(() => {
            if (roadMapData.value) return roadMapData.value.redCount
            return 0
        })

        const blueCount = computed(() => {
            if (roadMapData.value) return roadMapData.value.blueCount
            return 0
        })

        const greenCount = computed(() => {
            if (roadMapData.value) return roadMapData.value.tieCount
            return 0
        })

        const redPairCount = computed(() => {
            if (roadMapData.value) return roadMapData.value.redPairCount
            return 0
        })

        const bluePairCount = computed(() => {
            if (roadMapData.value) return roadMapData.value.bluePairCount
            return 0
        })

        const tableName = computed(() => {
            if (deskData.value) {
                const { gameTypeNo, tableNo } = deskData.value.gameTable

                if (gameTypeNo.toUpperCase() === 'G002')
                    return `${t('desklist.baccarattable', {
                        tablenumber: tableNo
                    })}`
                else
                    return `${t('desklist.dragontigertable', {
                        tablenumber: tableNo
                    })}`
            }
            return ''
        })

        const gameid = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.expand.gameNo
            }

            return '0'
        })

        const gameStatus = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.status
            }

            return 0
        })

        const minAndMaxTableLimit = computed(() => {
            if (deskData.value) {
                const { gameTypeNo } = deskData.value.gameTable

                const limit = limitOptionArray.value.find(
                    (s) => s.id === userLimit.value
                )

                if (limit) {
                    const {
                        downBaccaratLimit,
                        upBaccaratLimit,
                        downDragonLimit,
                        upDragonLimit
                    } = limit

                    if (gameTypeNo === 'G002') {
                        return `${downBaccaratLimit}-${upBaccaratLimit}`
                    } else if (gameTypeNo === 'G003') {
                        return `${downDragonLimit}-${upDragonLimit}`
                    } else {
                        return '0-0'
                    }
                }

                return '0-0'
            }
            return '0-0'
        })

        const gameTable = computed(() => {
            if (deskData.value) return deskData.value.gameTable

            return undefined
        })

        const betRedCommision = computed(() => {
            if (deskCommissionStatus.value) {
                return memberCommisionStatus.value
            }

            return true
        })

        const displayOptionLabel = computed(() => {
            const displayValue = displayOption.value
            if (displayValue === 0) return t('common.allroad')
            else if (displayValue === 1) return t('common.mainroad')
            else return t('common.statistics')
        })

        const blueStat = computed(() => {
            if (roadMapData.value.round === 0) return '0%'
            else
                return (
                    Math.floor(
                        (blueCount.value / roadMapData.value.round) * 100
                    ) + '%'
                )
        })

        const redStat = computed(() => {
            if (roadMapData.value.round === 0) return '0%'
            return (
                Math.floor((redCount.value / roadMapData.value.round) * 100) +
                '%'
            )
        })

        const greenStat = computed(() => {
            if (roadMapData.value.round === 0) return '0%'
            return (
                Math.floor((greenCount.value / roadMapData.value.round) * 100) +
                '%'
            )
        })

        const pairLimit = computed(() => {
            if (deskData.value) {
                const limit = limitOptionArray.value.find(
                    (s) => s.id === userLimit.value
                )

                if (limit) {
                    const { pairDownBaccaratLimit, pairUpBaccaratLimit } = limit

                    return `${pairDownBaccaratLimit}-${pairUpBaccaratLimit}`
                }

                return '0-0'
            }
            return '0-0'
        })
        const tieLimit = computed(() => {
            if (deskData.value) {
                const { gameTypeNo } = deskData.value.gameTable

                const limit = limitOptionArray.value.find(
                    (s) => s.id === userLimit.value
                )

                if (limit) {
                    const {
                        tieDownBaccaratLimit,
                        tieUpBaccaratLimit,
                        tieDownDragonLimit,
                        tieUpDragonLimit
                    } = limit

                    if (gameTypeNo === 'G002')
                        return `${tieDownBaccaratLimit}-${tieUpBaccaratLimit}`
                    else if (gameTypeNo === 'G003') {
                        return `${tieDownDragonLimit}-${tieUpDragonLimit}`
                    }
                    return '0-0'
                }

                return '0-0'
            }
            return '0-0'
        })

        const gameTableLimit = computed(() => {
            if (deskData.value) {
                return `${t('ingame.limitpot')}: ${
                    deskData.value.gameTable.downLimit
                }/${deskData.value.gameTable.upLimit}`
            } else {
                return `${t('ingame.limitpot')}: 0/0`
            }
        })

        const gameTableDownLimit = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.downLimit
            }
            return '0'
        })

        const gameTableUpLimit = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.upLimit
            }
            return '0'
        })

        const roundCount = computed(() => roadMapData.value.round)

        const maxTime = computed(() => {
            if (deskData.value) return deskData.value.gameTable.betTime
            return -1
        })

        const isMobile = computed(() => store.getters['isMobile'])
        const isClassic = computed(() => {
            return true
            //props.hall === 2
        })
        //#endregion

        //#region Watcher

        watch(
            () => videoIsOn.value,
            (v) => {
                if (v !== deskVideoOn.value) {
                    deskVideoOn.value = v

                    if (deskVideoOn.value) {
                        if (deskData.value) initVideoPlayer()
                    } else {
                        disposeVideoPlayer()
                    }
                }
            }
        )

        watch(
            () => deskData.value,
            (v) => {
                if (v) {
                    if (v.gameTable.expand.daoJiShiBetTime > 0) {
                        startCountDown(v.gameTable.expand.daoJiShiBetTime)
                    } else {
                        stopCountDown()
                    }
                }
            },
            { deep: true }
        )

        watch(
            () => props.desk,
            (v) => {
                stopCountDown()

                showCardDisplay.value = false

                betViewKey.value = `betview${new Date().getTime()}`

                winResult.value = []
                deskData.value = undefined
                roadMapData.value = parseRoadMap([])
                hideVideoCover.value = false
                loadData()

                videoBg.value = generateRandomNumber(1, 8)
            },
            { immediate: true }
        )

        watch(
            () => deviceOrientation.value,
            (v) => {
                // showSwitcher.value = false
                // showMobileSwitchDesk.value = false
                // showSwitchDesk.value = false

                // hide card display when orientation is change
                // if (typeof showCardTimeout === 'number') {
                //     clearTimeout(showCardTimeout)
                //     showCardTimeout = false
                //     showCardDisplay.value = false
                // }
                // gameKeyRef.value = `game-ref-key${new Date().getTime()}`

                // loadData()

                refMobileBetGroup.value.resetTempBet()
                refBetGroup.value.resetTempBet()

                if (typeof videoTimeOut === 'number') {
                    clearTimeout(videoTimeOut)
                    videoTimeOut = false
                }
                hideVideoCover.value = false
                disposeVideoPlayer()
                videoTimeOut = setTimeout(() => {
                    // if (v === 'landscape') {
                    //     nodePlayer.setView('videoplayer')
                    //     nodePlayer.setScaleMode(0)
                    // } else {
                    //     nodePlayer.setView('m-videoplayer')
                    //     nodePlayer.setScaleMode(2)
                    // }
                    initVideoPlayer()
                }, 500)
            }
        )

        watch(
            () => currentCount.value,
            (v) => {
                if (v === -1) {
                    if (deskData.value) {
                        deskData.value.gameTable.status = 2
                        deskData.value.gameTable.expand.daoJiShiBetTime = -1
                    }
                }
            }
        )
        //#endregion
        return {
            isLoadingVideo,
            videoBg,
            maxTime,
            gameStatus,
            greenStat,
            redStat,
            blueStat,
            roundCount,
            deskNoticeKey,
            deskNotice,
            deskData,
            mapData,
            deskMapKey1,
            deskMapKey2,
            deskMapKey3,
            deskMapKey4,
            gameType,
            nextRedRoadMapSummaryData,
            nextBlueRoadMapSummaryData,
            redLabel,
            blueLabel,
            redCount,
            blueCount,
            greenCount,
            redPairCount,
            bluePairCount,
            currentCount,
            tableName,
            coin,
            chip,
            isfullScreen,
            gameid,
            minAndMaxTableLimit,
            pairLimit,
            tieLimit,
            mapLeft,
            mapRight,
            mapMLeft,
            mapMRight,
            deskCommissionStatus,
            memberCommisionStatus,
            toastMessage,
            gameTable,
            showCardDisplay,
            betRedCommision,
            winResult,
            hasTempBet,
            refBetGroup,
            refMobileBetGroup,
            betViewKey,
            displayOption,
            displayOptionLabel,
            isMobile,
            hideVideoCover,
            gameTableLimit,
            gameTableDownLimit,
            gameTableUpLimit,
            gameKeyRef,
            isClassic,
            displayTableInfo,
            handleBetgroupUpdate,
            predict,
            requestFullScreen,
            onRoadMapDataParsed,
            setMemberCommission,
            showUiGameResult,
            cancelTempbet,
            confirmTempBet,
            backHistory,
            toggleDisplayOptionView,
            t,
            toggleShowExtraLimitInfo
        }
    }
})
