import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d0b48f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-prediction-wrap" }
const _hoisted_2 = { class: "mp-div-1" }
const _hoisted_3 = { class: "mp-div-1" }
const _hoisted_4 = { class: "mp-div-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["mobile-prediction-root", _ctx.color === 1 ? 'red' : 'blue'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.hasNoData)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["icon-b1", _ctx.bigeyeColor])
            }, null, 2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.hasSmall)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["icon-b2", _ctx.smallColor])
            }, null, 2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.hasCockroach)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["icon-b3", _ctx.cocroachColor])
            }, null, 2))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}