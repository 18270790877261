import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5682a36e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-result-v1-root" }
const _hoisted_2 = { class: "point-info" }
const _hoisted_3 = ["data-point"]
const _hoisted_4 = { class: "cards" }
const _hoisted_5 = { class: "p3" }
const _hoisted_6 = { class: "p1" }
const _hoisted_7 = { class: "p2" }
const _hoisted_8 = { class: "point-info" }
const _hoisted_9 = ["data-point"]
const _hoisted_10 = { class: "cards" }
const _hoisted_11 = { class: "p1" }
const _hoisted_12 = { class: "p2" }
const _hoisted_13 = { class: "p3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["b1", { reverse: _ctx.gameType !== 1 }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          "data-label": "闲",
          "data-point": _ctx.blueTotal
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_ctx.blueThirdCard !== '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["card", _ctx.blueThirdCard])
              }, null, 2))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.blueSecondCard !== '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["card", _ctx.blueSecondCard])
              }, null, 2))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.blueFirstCard !== '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["card", _ctx.blueFirstCard])
              }, null, 2))
            : _createCommentVNode("", true)
        ])
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["b2", { reverse: _ctx.gameType !== 1 }])
    }, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", {
          "data-label": "庄",
          "data-point": _ctx.redTotal
        }, null, 8, _hoisted_9)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          (_ctx.redFirstCard !== '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["card", _ctx.redFirstCard])
              }, null, 2))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_12, [
          (_ctx.redSecondCard !== '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["card", _ctx.redSecondCard])
              }, null, 2))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_13, [
          (_ctx.redThirdCard !== '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["card", _ctx.redThirdCard])
              }, null, 2))
            : _createCommentVNode("", true)
        ])
      ])
    ], 2)
  ]))
}