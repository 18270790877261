
import { saveBet } from '@/api'
import { AppMutation } from '@/store/types'
import { BetType, EventBuss } from '@/types/global'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import {
    ChipItem,
    Emitter,
    PropType,
    TableData,
    computed,
    defineComponent,
    inject,
    ref,
    watch
} from 'vue'
import { GAMETYPE } from '../roadmap/datamodel/types'
import { isClicked } from '@/utils'
import { useStore } from '@/store'
import BetBox from '@/components/bet/pc-deffault-bet-box-v1.vue'

export default defineComponent({
    emits: ['on-update'],
    props: {
        mData: {
            type: Object as PropType<TableData>,
            require: false
        },
        chip: {
            type: Object as PropType<ChipItem>,
            require: false
        },
        isMemberCommission: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: () => true
        },
        win: {
            type: Array as PropType<number[]>,
            default: () => []
        }
    },
    components: {
        'bet-box': BetBox
    },
    setup(props, { emit }) {
        const emitter = inject('emitter') as Emitter
        const store = useStore()

        const pBet = ref<number>(0)
        const bBet = ref<number>(0)
        const ppBet = ref<number>(0)
        const bpBet = ref<number>(0)
        const tBet = ref<number>(0)
        const drBet = ref<number>(0)
        const tgBet = ref<number>(0)

        const tempPBet = ref<number>(0)
        const tempBBet = ref<number>(0)
        const tempPpBet = ref<number>(0)
        const tempBpBet = ref<number>(0)
        const tempTBet = ref<number>(0)
        const tempDrBet = ref<number>(0)
        const tempTgBet = ref<number>(0)

        // #region betbox ref
        // classic baccarat
        const bankerBox = ref()
        const playerBox = ref()
        const tieBox = ref()
        const bankerPBox = ref()
        const playerPBox = ref()
        // classic dragon tiger
        const dragonBox = ref()
        const tigerBox = ref()
        const tieTGBox = ref()
        // endregion

        const placeAbet = (e: MouseEvent, _betType: number) => {
            const boxIsClicked = isClicked(e, 0.3)

            if (boxIsClicked) {
                if (!props.mData) return

                const { mData, chip } = props

                // uncomment this for real desk betting

                if (mData.status !== 1) {
                    // countdown timer is done, no betting allowed
                    emitter.emit(EventBuss.SMALL_TOAST, {
                        msg: '当前不能下注',
                        id: props.mData?.id || 0
                    })
                    return
                }

                const _amount = chip?.value || 0
                if (_amount > 0) {
                    // check total temp bet is sufficient
                    if (!isBetIsSufficient(_amount)) {
                        emitter.emit(EventBuss.SMALL_TOAST, {
                            msg: ' 余额不足，投注失败！',
                            id: props.mData?.id || 0
                        })

                        return
                    }

                    switch (_betType) {
                        case BetType.BANKER:
                            tempBBet.value += _amount
                            break
                        case BetType.DRAGON:
                            tempDrBet.value += _amount
                            break
                        case BetType.PLAYER:
                            tempPBet.value += _amount
                            break
                        case BetType.TIGER:
                            tempTgBet.value += _amount
                            break
                        case BetType.BC_TIE:
                        case BetType.DT_TIE:
                            tempTBet.value += _amount
                            break
                        case BetType.PLAYER_PAIR:
                            tempPpBet.value += _amount
                            break
                        case BetType.BANKER_PAIR:
                            tempBpBet.value += _amount
                            break
                    }

                    emitter.emit(EventBuss.PLACE_CHIP, {
                        t: _betType,
                        amt: _amount,
                        type: 'temp',
                        deskId: mData.id
                    })

                    audioPlayer.Play(SOUNDKEY.BET_PLACE)

                    callUpdateCallback()
                }
            }
        }

        const isBetIsSufficient = (amount: number) => {
            const total =
                tempPBet.value +
                tempBBet.value +
                tempPpBet.value +
                tempBpBet.value +
                tempTBet.value +
                tempDrBet.value +
                tempTgBet.value +
                amount

            return coin.value >= total
        }

        const callUpdateCallback = () => {
            const hasTempBet =
                tempPBet.value +
                    tempBBet.value +
                    tempPpBet.value +
                    tempBpBet.value +
                    tempTBet.value +
                    tempDrBet.value +
                    tempTgBet.value >
                0

            emit('on-update', hasTempBet)
        }

        const resetTempBet = () => {
            tempPBet.value = 0
            tempBBet.value = 0
            tempPpBet.value = 0
            tempBpBet.value = 0
            tempTBet.value = 0
            tempDrBet.value = 0
            tempTgBet.value = 0

            const gType = gameType.value === 'G002' ? 1 : 2

            // classic

            if (gType === GAMETYPE.baccarat) {
                // bac
                if (bankerBox.value) bankerBox.value.removeTempUIBet()
                if (playerBox.value) playerBox.value.removeTempUIBet()
                if (tieBox.value) tieBox.value.removeTempUIBet()
                if (bankerPBox.value) bankerPBox.value.removeTempUIBet()
                if (playerPBox.value) playerPBox.value.removeTempUIBet()
            } else if (gType === GAMETYPE.dragonTiger) {
                // dragon tiger
                if (dragonBox.value) dragonBox.value.removeTempUIBet()
                if (tigerBox.value) tigerBox.value.removeTempUIBet()
                if (tieTGBox.value) tieTGBox.value.removeTempUIBet()
            }

            callUpdateCallback()
        }

        const resetBetUI = () => {
            const gType = gameType.value === 'G002' ? 1 : 2
            if (props.mData) {
                const { id } = props.mData
                if (gType === GAMETYPE.baccarat) {
                    // bac
                    if (bankerBox.value && bBet.value > 0)
                        bankerBox.value.handleOnPlaceChip({
                            t: BetType.BANKER,
                            amt: bBet.value,
                            type: 'confirmed',
                            deskId: id
                        })
                    if (playerBox.value && pBet.value > 0) {
                        playerBox.value.handleOnPlaceChip({
                            t: BetType.PLAYER,
                            amt: pBet.value,
                            type: 'confirmed',
                            deskId: id
                        })
                    }

                    if (tieBox.value && tBet.value > 0) {
                        tieBox.value.handleOnPlaceChip({
                            t: BetType.BC_TIE,
                            amt: tBet.value,
                            type: 'confirmed',
                            deskId: id
                        })
                    }

                    if (bankerPBox.value && bpBet.value > 0) {
                        bankerPBox.value.handleOnPlaceChip({
                            t: BetType.BANKER_PAIR,
                            amt: bpBet.value,
                            type: 'confirmed',
                            deskId: id
                        })
                    }

                    if (playerPBox.value && ppBet.value > 0) {
                        playerPBox.value.handleOnPlaceChip({
                            t: BetType.PLAYER_PAIR,
                            amt: ppBet.value,
                            type: 'confirmed',
                            deskId: id
                        })
                    }
                } else {
                    if (tigerBox.value && tgBet.value > 0) {
                        tigerBox.value.handleOnPlaceChip({
                            t: BetType.TIGER,
                            amt: tgBet.value,
                            type: 'confirmed',
                            deskId: id
                        })
                    }

                    if (dragonBox.value && drBet.value > 0) {
                        dragonBox.value.handleOnPlaceChip({
                            t: BetType.DRAGON,
                            amt: drBet.value,
                            type: 'confirmed',
                            deskId: id
                        })
                    }

                    if (tieTGBox.value && tBet.value > 0) {
                        tieTGBox.value.handleOnPlaceChip({
                            t: BetType.DT_TIE,
                            amt: tBet.value,
                            type: 'confirmed',
                            deskId: id
                        })
                    }
                }
            }
        }

        const confirmTempBet = () => {
            if (!props.mData) return
            let _params: any = {}
            const { expand, id } = props.mData

            const gType = gameType.value === 'G002' ? 1 : 2
            if (gType === 1) {
                _params = {
                    memberId: memberId.value,
                    tableId: id,
                    shoeNo: expand.shoeNo,
                    juNo: expand.juNo,
                    gameNo: expand.gameNo,
                    zhuang: tempBBet.value,
                    he: tempTBet.value,
                    xian: tempPBet.value,
                    zhuangDui: tempBpBet.value,
                    xianDui: tempPpBet.value,
                    token: token.value
                }
            } else if (gType === 2) {
                _params = {
                    memberId: memberId.value,
                    tableId: id,
                    shoeNo: expand.shoeNo,
                    juNo: expand.juNo,
                    gameNo: expand.gameNo,
                    zhuang: tempDrBet.value,
                    he: tempTBet.value,
                    xian: tempTgBet.value,
                    token: token.value
                }
            }

            store.commit(AppMutation.SHOW_LOADING)

            saveBet(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (response.data.status === 100) {
                        emitter.emit(EventBuss.SMALL_TOAST, {
                            msg: response.data.description,
                            id: props.mData?.id || 0
                        })

                        resetTempBet()
                    } else if (response.data.status === 200) {
                        emitter.emit(EventBuss.SMALL_TOAST, {
                            msg: '下注成功',
                            id: props.mData?.id || 0
                        })
                        audioPlayer.Play(SOUNDKEY.BET_SUCCESS)
                        applyTempBet()
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    resetTempBet()
                    emitter.emit(EventBuss.SMALL_TOAST, {
                        msg: '当前网络不佳，请重新尝试',
                        id: props.mData?.id || 0
                    })
                })
        }

        const applyTempBet = () => {
            tempPBet.value = 0
            tempBBet.value = 0
            tempPpBet.value = 0
            tempBpBet.value = 0
            tempTBet.value = 0
            tempDrBet.value = 0
            tempTgBet.value = 0

            if (bankerBox.value) bankerBox.value.applyTempUIBet()
            if (playerBox.value) playerBox.value.applyTempUIBet()
            if (tieBox.value) tieBox.value.applyTempUIBet()
            if (bankerPBox.value) bankerPBox.value.applyTempUIBet()
            if (playerPBox.value) playerPBox.value.applyTempUIBet()

            // dragon tiger
            if (dragonBox.value) dragonBox.value.applyTempUIBet()
            if (tigerBox.value) tigerBox.value.applyTempUIBet()
            if (tieTGBox.value) tieTGBox.value.applyTempUIBet()

            callUpdateCallback()
        }

        const endGame = () => {
            // bac
            if (bankerBox.value) bankerBox.value.resetBetUI()
            if (playerBox.value) playerBox.value.resetBetUI()
            if (tieBox.value) tieBox.value.resetBetUI()
            if (bankerPBox.value) bankerPBox.value.resetBetUI()
            if (playerPBox.value) playerPBox.value.resetBetUI()

            // dragon tiger
            if (dragonBox.value) dragonBox.value.resetBetUI()
            if (tigerBox.value) tigerBox.value.resetBetUI()
            if (tieTGBox.value) tieTGBox.value.resetBetUI()

            resetTempBet()
        }

        const gameType = computed(() => {
            let result = ''
            if (props.mData) result = props.mData.gameTypeNo
            else result = ''

            return result
        })

        const memberId = computed(() => store.getters['memberid'])
        const token = computed(() => store.getters['token'])
        const coin = computed((): number => store.getters['coin'])

        watch(
            () => props.mData,
            (val, old) => {
                if (val && val.expand.betFormList) {
                    let _pBet = 0
                    let _bBet = 0
                    let _ppBet = 0
                    let _bpBet = 0
                    let _tBet = 0
                    let _drBet = 0
                    let _tgBet = 0

                    const { betFormList } = val.expand
                    betFormList.forEach((i) => {
                        if (i.memberId === memberId.value) {
                            const { playName, amount } = i
                            if (playName === '庄') {
                                _bBet += amount
                            } else if (playName === '庄对') {
                                _bpBet += amount
                            } else if (playName === '闲') {
                                _pBet += amount
                            } else if (playName === '闲对') {
                                _ppBet += amount
                            } else if (playName === '和') {
                                _tBet += amount
                            } else if (playName === '龙') {
                                _drBet += amount
                            } else if (playName === '虎') {
                                _tgBet += amount
                            }
                        }
                    })
                    pBet.value = _pBet
                    bBet.value = _bBet
                    ppBet.value = _ppBet
                    bpBet.value = _bpBet
                    tBet.value = _tBet
                    drBet.value = _drBet
                    tgBet.value = _tgBet
                }
            },
            { immediate: true, deep: true }
        )

        return {
            gameType,

            // temp Baccarat
            tempPBet,
            tempBBet,
            tempPpBet,
            tempBpBet,

            // temp Dragon tiger
            tempTgBet,
            tempDrBet,
            tempTBet,

            // classic baccarat
            bankerBox,
            playerBox,
            tieBox,
            bankerPBox,
            playerPBox,
            // classic dragon tiger
            dragonBox,
            tigerBox,
            tieTGBox,

            pBet,
            bBet,
            ppBet,
            bpBet,
            tBet,
            drBet,
            tgBet,

            placeAbet,
            resetTempBet,
            resetBetUI,
            confirmTempBet,
            endGame
        }
    }
})
